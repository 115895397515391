<div class="modal-content">
  <div class="login-modal-header">
    <h3 class="mb-0" *ngIf="isUserTypeGuest && !guestloginCoupon">{{'web.login_popup_top_txt' | translate }}</h3>
    <h3 class="mb-0" *ngIf="isUserTypeGuest && guestloginCoupon">{{'web.guest_coupon_login_title' | translate }}</h3>
   
    <a class="closeModal" autofocus="" *ngIf="isUserTypeGuest && showCloseButton"
      [routerLink]="redirectToHome? ['/'] : []" (click)="
      activeModal.dismiss()" data-bs-dismiss="modal">
      <img src="{{backCloseIcon}}" alt="" title="" (click)="closeLogin()">
    </a>
    <a class="closeModal" autofocus="" *ngIf="!isUserTypeGuest && showCloseButton"
      [routerLink]="redirectToHome? ['/'] : []" (click)="
      close()" data-bs-dismiss="modal">
      <img src="{{backCloseIcon}}" alt="" title="">
    </a>
  </div>
  <div class="modal-body">
    <div class="login_text" *ngIf="displayKeysforGuest">{{'web.login_popup_guest_user_txt' | translate }}</div>
    <div class="login_text" *ngIf="isUserTypeGuest && !guestloginCoupon" >{{'web.ie_guest_login_popup_sub_title' | translate }}</div>
    <div class="login_text" *ngIf="isUserTypeGuest && guestloginCoupon">  {{'web.guest_coupon_login_sub_title' | translate }}</div> 
  

<!-- 
    <div class="login_text" *ngIf="displayKeysforGuest">{{'web.login_popup_guest_user_txt' | translate }}</div>
    <div class="login_text" *ngIf="isUserTypeGuest">{{'web.ie_guest_login_popup_sub_title' | translate }}</div> -->
    <h4 class="resend-massage">
      <div [ngStyle]="styleClassApiMsg" *ngIf="showMsgFlag">{{confirmationMsg}}</div>
    </h4>
    <form class="login_form" #loginForm="ngForm" name="form" (ngSubmit)="loginForm.form.valid && processLogin()"
      novalidate>
      <input id="userEmail" name="email" [(ngModel)]="userEmail" #email="ngModel" class="form-control" pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$"
        [ngClass]="{ 'error': (email.touched && email.invalid) || (loginForm.submitted && email.invalid)}"
        placeholder="{{'web.login_popup_placeholder_txt_email' | translate }}" required>
          <div class="error-msg" *ngIf="email.dirty || email.touched">
              <span class="text-danger" *ngIf="email.invalid && email.errors.pattern">
                  {{'web.register_val_invalid_email' | translate }}
              </span>
          </div>

      <input id="userPassword" name="password" type="password" [(ngModel)]="userPassword" #password="ngModel"
        class="form-control" placeholder="{{'web.login_popup_placeholder_txt_password' | translate }}"
        [ngClass]="{ 'error': (password.touched && password.invalid) || (loginForm.submitted && password.invalid)}"
        required>
      <div *ngIf="code2 || code4" class="resend-mail-box">
        <small class="text-danger">{{verifyMsg}}</small>
      </div>
      <div *ngIf="code3" class="resend-mail-box">
        <small>{{partialRegMsg}}</small>
        <div (click)="verifyEmail()">
          <a>{{ 'web.login_pop_up_resend_verification_email' | translate }}</a>
        </div>
      </div>
      <div class="login_buttons">
        <button class="btn btn-primary btn-md w-100 d-block" type="submit">
          <span class="me-2">{{'web.login_cta_txt' | translate }}</span>
          <img src="{{imageIconPath}}small-loader.gif" alt="" title="" *ngIf="loginInProgress"/>
        </button>
        <div class="forgotPwd_wrap">
          <a (click)="forgotPwd()">{{'web.login_forgot_password' | translate }}</a>
        </div>
        <!-- <div class="forgotPwd_wrap" >
					<a (click)="goToRegister()">{{'web.registration_cta_txt' | translate }}</a>
				</div> -->
      </div>
    </form>
    <div class="text-line" *ngIf="isUserTypeGuest">
      <span>{{'web.login_option_or_txt' | translate }}</span>
    </div>
    <div class="register-cta">
      <a (click)="goToRegister()">{{'web.registration_cta_txt' | translate }}</a>
    </div>
    <!-- <div class="guest_user_buttons" *ngIf="isUserTypeGuest">
			<button class="btn btn-primary w-100 d-block" *ngIf="isUserTypeGuest" style="margin-top: 10px;" (click)="loginWithGuestUser()">{{'web.continue_guest_cta_txt' | translate }}</button>
			<p *ngIf="isUserTypeGuest">{{'web.login_guest_user_text' | translate }}</p>
		</div> -->
  </div>
</div>