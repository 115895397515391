import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// import ngx-translate and the http loader
import { TranslateModule} from '@ngx-translate/core';

import { FormsModule, ReactiveFormsModule} from '@angular/forms';
import { UserRoutingModule } from './user-routing.module';
import { RegisterComponent } from './register/register.component';
import { LoginComponent } from './login/login.component';
import { ForgotpasswordComponent } from './forgotpassword/forgotpassword.component';
import { EmailSharedModule } from '../emailShared.module';
import { QrCodePopupComponent } from './qr-code-popup/qr-code-popup.component';
import { CustomCommonModule } from '../custom-common/common.module';
import { Login19Component } from './login19/login19.component';


@NgModule({
  declarations: [RegisterComponent, LoginComponent, ForgotpasswordComponent,QrCodePopupComponent, Login19Component],
  imports: [
    CommonModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    UserRoutingModule,
    EmailSharedModule,
    CustomCommonModule
  ],
  exports: [RegisterComponent, LoginComponent, ForgotpasswordComponent,QrCodePopupComponent]
})
export class UserModule { }
