import { Component, OnInit, Input, ViewChild, Renderer2 } from '@angular/core';
import { Router, ActivatedRoute} from '@angular/router';
import { environment } from 'src/environments/environment';
import { DomSanitizer } from '@angular/platform-browser';
import * as global from 'src/app/globals';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription ,ReplaySubject} from 'rxjs';
import { GlobalService } from 'src/app/services/global.service';
import { DataService } from 'src/app/services/data.service';
import { takeUntil } from 'rxjs/operators';
import { ContentfulService } from 'src/app/services/contentful.service';
declare var $: any;

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
    standalone: false
})
export class LoginComponent implements OnInit {
    @ViewChild('loginForm') loginForm: any;
    @Input() public productdata;
    isFormValid = false;
    loginInProgress = false;
    // inProgress: Boolean = false;
    userInfo: any = {};
    code5 = false;
    code3 = false;
    code4 = false;
    code2 = false;
    status: any;
    verifyMsg;
    userAuthKey: any;
    userName: any;
    userOldPassword: any;
    regConfCode: any;
    partialRegMsg: any;
    isUserLoggedIn: boolean;
    email: any;
    showMsgFlag = false;
    confirmationMsg = undefined;
    styleClassApiMsg: any = {};
    arrCartLength = 0;
    userPassword: any;
    userEmail: any;
    dicUrls: any;
    envCode: any;
    previousUrl: any;
    newsletterUserEmail: any;
    cancelLoginPopup: Boolean = false;
    smallLogoImage: any;
    isUserTypeGuest: Boolean = true;
    cartPageUrl: any;
    displayKeysforGuest: any;
    guestloginCoupon: any;
    backCloseIcon:any;
    redirectToHome:Boolean=false;
    showCloseButton:any;
    totalProductNo: any;
    totalOrderNo: any;
    albmData = [];
    loginModalSubscription: Subscription;
    languageDataSubscription: Subscription;
    private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
    imageIconPath: any;
    constructor(private globalService:GlobalService, public activeModal: NgbActiveModal, private modalService: NgbModal,public contentfulService: ContentfulService,
    private dataService: DataService, private route: ActivatedRoute,private renderer: Renderer2, private router: Router, private sanitizer: DomSanitizer) {
        this.backCloseIcon = environment[global.env].RESOURCE_URL_AMAZON+environment[global.env].oemCode+ '/icons/checkmark-cross-32.svg';
    }


    ngOnInit() {
    let currentRoute=this.router.url.split('/')[1].split('?')[0];
    // 
    if(currentRoute=='fotos-hochladen'){
     this.showCloseButton=false;
    }else{
     this.showCloseButton=true;
    }
    if(currentRoute=='kundenservice' || currentRoute=='customer-service'){
       this.redirectToHome=true;
    }
    else{

        this.redirectToHome=false;
    }
        this.envCode = environment[global.env].oemCode;
        this.previousUrl = this.globalService.getPreviousUrl();
        this.newsletterUserEmail = this.previousUrl[1];

        if(localStorage.getItem('userInfo')){
           let userInfo =  JSON.parse(localStorage.getItem('userInfo'));
           if(userInfo.userType == 'Guest'){
                this.displayKeysforGuest = this.productdata.displayKeysforGuest;
                this.guestloginCoupon = this.productdata.guestloginCoupon;
                this.isUserTypeGuest = true;
           };
        }
        if(Object.keys(this.productdata).length != 0){
            if(this.productdata.email != undefined){
                this.userEmail = this.productdata.email;
            }
        }

        this.dicUrls = this.globalService.getStaticPagesUrls(global.env, global.lang);
        this.cartPageUrl = this.dicUrls['CART_PREVIEW'];


    }
    preventEnterScroll(event: KeyboardEvent): void {
        if (event.key === 'Enter') {
            event.preventDefault();  // Prevent default form submission behavior
    
            // Safari-specific adjustment if necessary
            if (navigator.userAgent.includes('Safari') && !navigator.userAgent.includes('Chrome')) {
                // Handle Safari-specific adjustments here if needed
                // console.log("Prevented Safari's default scroll on Enter key");
            }
    
            // Optionally, trigger the login action manually if not done on form submission
            if (this.loginForm.form.valid) {
                this.processLogin();
            }
        }
    }
    
    processLogin() {
        if (this.loginForm.invalid) {
            this.isFormValid = true;
            return;
        }

        this.loginInProgress = true;
        let userDeatils = this.loginForm.form.value;
        this.email = userDeatils.email;
        let userPassword = userDeatils.password;
        let userLowerCase = this.email.toLowerCase();

        this.dataService.login(userLowerCase, userPassword).subscribe((res: Response) => {
            let resSTR = JSON.stringify(res);
            let resJSON = JSON.parse(resSTR);
            let userAuth = resJSON.auth;
            if (resJSON.success == true) {
                if (resJSON.status.code == 5) {
                    this.code5 = true;
                    this.verifyMsg = resJSON.message;
                    this.loginInProgress = false;
                    return;
                }
                if (resJSON.status.code == 7) {
                    this.close();
                    this.status = 7;
                    this.userAuthKey = resJSON.auth;
                    this.router.navigate(['forgot-password']);
                }
                else if (resJSON.status.code == 0 || resJSON.status.code == 3 || resJSON.status.code == 6) {

                    var userData =  resJSON;
                    this.userOldPassword = userPassword;
                    var userAuthKey = resJSON.auth;
                    this.setgtmCodeForLogin(resJSON.data.userNumber);
                    var getSsoIdDetail = {
                        "application": "IPHONE_APP",
                        "data":
                        {
                            "oemCode": environment[global.env].oemCode,
                            // "userName": resJSON.data.Username.toLowerCase(),
                            "password": userPassword,
                            // "clientName": "test",
                            "clientVersion": "v1.2"
                        }
                    }
                    this.dataService.getSsoId(getSsoIdDetail, resJSON.auth).subscribe((res: Response) => {
                        let resSTR = JSON.stringify(res);
                        let resJSON = JSON.parse(resSTR);
                        if (resJSON.success == true)
                        {
                            let createNow = localStorage.getItem('createNow');
                            this.cancelLoginPopup = false;
                            if(this.previousUrl[0] == 'newslettersubscribe'){
                                this.cancelLoginPopup = true;
                            }
                            this.close();

                            let arrurl  = this.router.url;
                            let currentState = arrurl;
                            let arrPath = arrurl.split('/');
                            currentState = decodeURIComponent(currentState);

                            this.userInfo = { FirstName: userData.data.FirstName, LastName: userData.data.LastName, Email: userData.data.Email, Username: userData.data.Username, isDesigner:userData.data.isDesigner, status:userData.status.code, auth: userData.auth,
                                userType:userData.data.user_type,userLoginKey: userData.data.userLoginKey, Newsletter: userData.data.Newsletter, langCode : userData.data.langCode,  SSO_ID: resJSON.sessionId, userId:userData.data.userNumber};
                            this.globalService.setUserData(this.userInfo);
                            localStorage.setItem('userInfo', JSON.stringify(this.userInfo));
                             this.globalService.setSessionWindow();
                            this.globalService.startWatchingUserDuration()
                            localStorage.removeItem("isUserTypeGuest");
                            localStorage.removeItem("address");
                            this.getDataCount();
                            // this.router.navigateByUrl('', { skipLocationChange: true }).then(() => {
                            //     this.router.navigate([currentState]);
                            // });

                            if(this.productdata.page == 'quickBook'){
                                let productCode = this.productdata.product.productCode;
                                if(this.productdata.product.theme != ''){
                                   this.router.navigate(['create/easy/'+productCode+'/'+this.productdata.projectId], { queryParams: { themeId: this.productdata.product.theme.id } });
                                }
                                else{
                                    this.router.navigate(['create/easy/'+productCode+'/'+this.productdata.projectId]);
                                }
                            }
                            else{
                                if(this.productdata.pageUrl){
                                if(this.productdata.pageUrl!='fotos-hochladen' &&this.productdata.pageUrl!='upload-photos' ){
                                    window.location.reload();
                                }

                                }
                                else if(createNow){
                                    this.globalService.createNow(this.productdata.product);
                                }
                                else{
                                    if(this.previousUrl[0] == 'newslettersubscribe'){
                                        if(this.userInfo.Email == this.newsletterUserEmail){
                                            if(this.userInfo.Newsletter == 0){
                                                this.newsletterSubscribe();
                                            }
                                            else{
                                                // this.globalService.setNewsLetterData(this.userInfo.Newsletter);
                                                this.router.navigate(['/']);
                                            }
                                        }
                                        else{
                                            // this.globalService.setNewsLetterNotMatchData(this.newsletterUserEmail);
                                            this.router.navigate(['/']);
                                            return;
                                        }
                                    }
                                    else if(arrPath[1] == 'register-confirmation'){
                                        this.router.navigate(['/']);
                                    }
                                    else if(arrPath[1] == 'mycart'){
                                        this.router.navigate([this.cartPageUrl]);
                                    }
                                    else{
                                        if(this.productdata){
                                            if(this.productdata.loginType == 'inPageEditor'){
                                                // this.router.navigate([currentState]);
                                                let data = { isLogin: true, loginType: this.productdata.loginType};
                                                this.globalService.setUserLoginData(data);
                                            }
                                            else if(this.productdata.loginType == 'uploader'){
                                                let data = { isLogin: true,  conponentType:this.productdata.conponentType};
                                                this.globalService.setUserLoginData(data);
                                            }
                                            else if(this.productdata.loginType == 'guest_user_login'){
                                                let data = { isLogin: true,component:this.productdata.component,loginType: this.productdata.loginType};
                                                this.globalService.setUserLoginData(data);
                                            }
                                            else if(this.productdata.redirectToctaLink && this.productdata.redirectToctaLink!=''){

                                            this.router.navigateByUrl(this.productdata.redirectToctaLink);

                                            }
                                            else{
                                                this.router.navigateByUrl('', { skipLocationChange: true }).then(() => {
                                                    this.router.navigate([currentState]);
                                                });
                                            }
                                        }
                                        else{
                                            this.router.navigateByUrl('', { skipLocationChange: true }).then(() => {
                                                this.router.navigate([currentState]);
                                            });
                                        }
                                    }
                                }
                            }
                        }
                    });


                    let auth = undefined;
                    auth = userAuth;
                    if (auth) {
                        this.dataService.getCartCount(auth).subscribe((res: Response) => {
                            let resSTR = JSON.stringify(res);
                            let resJSON = JSON.parse(resSTR);
                            if (resJSON.success == true) {
                                this.globalService.setCartLengthData(resJSON.data.items);

                            }
                        });
                    }

                    if (this.regConfCode) {
                        this.regConfCode = undefined;
                        this.router.navigate(['/']);
                    }
                }

            }
            else if (resJSON.status) {
                this.isUserLoggedIn = false;
                this.code3 = false;
                this.code4 = false;
                this.code2 = false;
                // this.user='';
                // this.pass='';
                this.loginInProgress = false;

                if (resJSON.status.code == 4) {

                    this.code4 = true;
                    this.verifyMsg = resJSON.status.message;
                }
                else if (resJSON.status.code == 2) {
                    this.code2 = true;
                    this.userEmail = '';
                    this.userPassword = '';
                    this.verifyMsg = resJSON.status.message;
                }
                else {
                    this.code2 = false;
                    this.code3 = false;
                    this.code4 = false;

                    var code = resJSON.status.code;
                    var code_msgg = resJSON.error.detail;
                }
            }
        });

    }

    verifyEmail() {
        let email = this.email
        if (!email) {
            return;
        }
        this.dataService.resendVerificationMail(email).subscribe((res: Response) => {
            let resSTR = JSON.stringify(res);
            let resJSON = JSON.parse(resSTR);
            this.showMsgFlag = true;
            this.confirmationMsg = resJSON.message;
            if (resJSON.success == true) {
                this.styleClassApiMsg.color = "green";
                // this.styleClassApiMsg.background = "#fef9ad";
                this.styleClassApiMsg.padding = "10";

            } else {
                this.styleClassApiMsg.color = "red";
            }
        })
    }

    close(){
        this.renderer.addClass(document.body, 'htmlscroll');
        this.activeModal.dismiss('Modal Closed');
        let arrurl  = this.router.url;
        let currentState = arrurl;
        currentState = decodeURIComponent(currentState);
        if(currentState === '/'){
            currentState = currentState;
        }
        else{
            if(currentState.charAt(0) === '/')
            {
                currentState = currentState.substr(1);
            }
        }

        if(currentState == 'customer-service' || currentState == 'kundenservice' || currentState == 'support')
        {
            this.router.navigate(['/']);
        }
    }

    closeModal() {

        this.activeModal.dismiss('Modal Closed');
        this.router.navigate(['/']);
    }

    newsletterSubscribe() {
        let email = this.newsletterUserEmail;
        var type = "subscribe"

        this.dataService.newsletterSubscribe(email, type).subscribe((res: Response) => {
            let resSTR = JSON.stringify(res);
            let resJSON = JSON.parse(resSTR);
            if(resJSON.success == true) {
                let obj: any;
                obj = {'newsletter': 0, 'succesMsg': resJSON.message};
                // this.globalService.setNewsLetterObj(obj);
                this.router.navigate(['/']);
            }
        });
    }

    goToRegister() {
        this.close();
        let data;
        if(this.productdata.page == 'product'){
            data = this.productdata;
            localStorage.setItem("createNow", 'true');
        }
        else if(this.productdata.loginType == 'inPageEditor'){
            data = this.productdata;
        }
        else{
            data = {type:"OPEN_REGISTER"};
        }
        this.globalService.setOpenRegister(data);
    }

    forgotPwd() {
        this.close();
        let data = {type:"OPEN_FORGOTPASSWORD"};
        this.globalService.setOpenForgotPassword(data);
    }

    loginWithGuestUser(){

        // this.inProgress = false;

        this.dataService.getGuestSession().subscribe((res: Response) => {
            let resSTR = JSON.stringify(res);
            let resJSON = JSON.parse(resSTR);
            if(resJSON.success == true) {

                let userInfo = {auth: resJSON.userAuth, langCode : global.lang, userType: resJSON.userType,
                    FirstName: '', LastName: '', Newsletter: 0, SSO_ID: resJSON.sessionId,  userId:resJSON.userNumber
                };

                let arrurl  = this.router.url;
                let currentState = arrurl;
                let arrPath = arrurl.split('/');
                currentState = decodeURIComponent(currentState);

                let createNow = localStorage.getItem('createNow');
                this.cancelLoginPopup = false;
                if(this.previousUrl[0] == 'newslettersubscribe'){
                    this.cancelLoginPopup = true;
                }
                this.globalService.setUserData(userInfo);
                localStorage.setItem('userInfo', JSON.stringify(userInfo));
                 this.globalService.setSessionWindow();
                this.globalService.startWatchingUserDuration();
                
                // let data = { isLogin: true};
                // this.globalService.setUserLoginData(data);
                this.close();
            }
        });
    }

    setgtmCodeForLogin(userId){
        //  Registration completed
        const gaScript = document.createElement('script');
        const date = new Date();
    let timestamp:any = date.getTime() * 1000;
        gaScript.innerHTML = `
        window.dataLayer = window.dataLayer || [];
        dataLayer.push({'event': 'login','userId':'`+userId +`' , 'custom_event_id':`+ timestamp + `})`;
        document.head.appendChild(gaScript);

       // End Registration completed
    }

    closeLogin(){
        
        let userInfo =  JSON.parse(localStorage.getItem('userInfo'));
        if(userInfo && userInfo.userType == 'Guest'){
            var info = {type:"guestLoginClose"}
            this.globalService.setCloseGuestLogin(info);
        }

        let currentRoute = this.router.url.split('/')[1].split('?')[0];
        if(currentRoute == 'account' && userInfo.userType == 'Guest') {
            this.redirectToHome=true;
        }
    }

    getDataCount(){
        this.dataService.myOrders(1, 100, 'No').pipe(takeUntil(this.destroyed$)).subscribe((res: Response) => {
             let resSTR = JSON.stringify(res);
            let resJSON = JSON.parse(resSTR);
            if (resJSON.success == true) {
                this.totalOrderNo = resJSON.totalRecord;
                localStorage.setItem('totalOrderCount', this.totalOrderNo);

            }
        },(error) => {
            
            })

            this.imageIconPath = environment[global.env].RESOURCE_URL_AMAZON + environment[global.env].RESOURCE_CONTAINER + environment[global.env].oemCode + "/icons/";
    }
    
}
